// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'http://localhost:4200',
  firebase: {
    apiKey: 'AIzaSyBHTJwLlZOM9gEs96XQs-bO8b8xQ9olMYo',
    authDomain: 'contentcrafter-882c8.firebaseapp.com',
    databaseURL: 'https://contentcrafter-882c8.firebaseio.com',
    imageBucket: 'contentcrafter-dev-images',
    projectId: 'contentcrafter-882c8',
    storageBucket: 'contentcrafter-882c8.appspot.com',
    messagingSenderId: '426063858271',
    appId: '1:426063858271:web:f560bb3ee9c343c86fe9ea',
    measurementId: 'G-KJV1TJRY89',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
