import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FirebaseApp } from '@angular/fire/app';
import {
  Auth,
  User as FireUser,
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  signInWithEmailAndPassword,
  FacebookAuthProvider,
  GoogleAuthProvider,
  signInWithPopup,
  signInAnonymously,
  sendPasswordResetEmail,
  AuthProvider,
  SAMLAuthProvider,
  OAuthProvider,
  getAdditionalUserInfo,
  UserCredential,
  updateEmail,
  updateProfile,
  deleteUser,
  TwitterAuthProvider,
  GithubAuthProvider,
} from '@angular/fire/auth';
import { Firestore, doc, getDoc, serverTimestamp, addDoc, collection } from '@angular/fire/firestore';

import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
// import { User } from '../models/user';
import { UserModel } from 'src/app/typings/user.model';
import { environment } from 'src/environments/environment';
import { ProviderInfo } from 'src/app/typings/auth.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<UserModel>;
  public currentUser: Observable<UserModel>;

  constructor(
    public fireAuth: Auth,
    public firestore: Firestore,
    public firebaseApp: FirebaseApp,
    private router: Router,
    private http: HttpClient
  ) {
    this.currentUserSubject = new BehaviorSubject<UserModel>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): UserModel {
    return this.currentUserSubject.value;
  }

  async setupUser(user: FireUser, userCred?: UserCredential) {
    const userDoc = doc(this.firestore, 'ContentCrafters', user.uid);
    const userSnapshot = await getDoc(userDoc);
    const userData = userSnapshot.data() as UserModel;

    localStorage.setItem('currentUser', JSON.stringify(user));
    this.currentUserSubject.next(userData);
    // if (!userData || !userData.activeOrg) {
    // if (userCred) await this.infoToProfile(user, userCred);
    // const joined = await this.checkInvites(user);
    // if (!joined) {
    // const [orgID, brandID] = await this.createDefaultOrgAndBrand(user);
    // }
    // }
    // this.router.navigate(['/home']);
  }

  async signUpWithEmail(email: string, password: string) {
    const resp = await createUserWithEmailAndPassword(this.fireAuth, email, password);
    // this.analyticsService.track('clicked sign up');
    await this.setupUser(resp.user);
    return resp;
  }

  async loginWithEmail(email: string, password: string, runSetup = true) {
    const resp = await signInWithEmailAndPassword(this.fireAuth, email, password);
    if (runSetup) await this.setupUser(resp.user);
    return resp;
  }

  async loginWithProvider(provider: string) {
    const prov = this.getProvider(provider);
    const resp = await signInWithPopup(this.fireAuth, prov);
    await this.setupUser(resp.user, resp);
    return resp;
  }

  getProvider(provider: string) {
    let prov: AuthProvider;
    switch (provider) {
      case 'google.com':
        prov = new GoogleAuthProvider();
        break;
      case 'twitter.com':
        prov = new TwitterAuthProvider();
        break;
      case 'github.com':
        prov = new GithubAuthProvider();
        break;
    }
    if (!prov) {
      const provId = provider.split('.')[0];
      switch (provId) {
        case 'saml':
          prov = new SAMLAuthProvider(provider);
          break;
        case 'oidc':
          prov = new OAuthProvider(provider);
          break;
      }
    }
    return prov;
  }

  async getProviderInfo(provider: string) {
    const provDoc = doc(this.firestore, 'idproviders', provider);
    const provSnapshot = await getDoc(provDoc);
    const provData = provSnapshot.data() as ProviderInfo;
    return { ...provData, providerId: provDoc.id };
  }

  sendPasswordResetEmail(email: string) {
    return sendPasswordResetEmail(this.fireAuth, email);
  }

  async checkEmail(email: string) {
    return fetchSignInMethodsForEmail(this.fireAuth, email).catch((err) => {
      if (err.code === 'auth/invalid-email') {
        return null;
      }
      console.error(err);
    });
  }

  login(username: string, password: string) {
    return this.http
      .post<any>(`${environment.apiUrl}/authenticate`, {
        username,
        password,
      })
      .pipe(
        map((user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes

          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
          return user;
        })
      );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    return of({ success: false });
  }
}
