import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { Page404Component } from 'src/app/components/page404/page404.component';
import { AuthGuard } from './pages/core/guard/auth.guard';
import { AuthLayoutComponent } from './components/auth-layout/auth-layout.component';
import { MainLayoutComponent } from 'src/app/components/main-layout/main-layout.component';
const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/auth/signup', pathMatch: 'full' },
      {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'advance-table',
        loadChildren: () => import('./components/advance-table/advance-table.module').then((m) => m.AdvanceTableModule),
      },
      {
        path: 'extra-pages',
        loadChildren: () => import('./pages/extra-pages/extra-pages.module').then((m) => m.ExtraPagesModule),
      },
      {
        path: 'multilevel',
        loadChildren: () => import('./pages/multilevel/multilevel.module').then((m) => m.MultilevelModule),
      },
    ],
  },
  {
    path: 'auth',
    component: AuthLayoutComponent,
    loadChildren: () => import('./pages/auth/auth.module').then((m) => m.AuthModule),
  },
  { path: '**', component: Page404Component },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
